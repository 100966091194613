



















































































































































































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject, Watch, Prop } from "vue-property-decorator";
import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { isEmpty } from "lodash-es";
import { TrainingGroundEventViewmodel } from "@/modules/training-ground-event/viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    CharacterRow: () => import("@/components/character/character-row.vue"),
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    OwnedMonsterFilter: () => import("../components/owned-monster-filter.vue"),
  },
})
export default class SelectMonsterDialog extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;
  @Inject() vm!: TrainingGroundViewmodel;

  get emptySearchParam() {
    return isEmpty(this.vm.searchParams);
  }

  async lockSelectingMonsters() {
    if (this.vm.isStaked) {
      const isSuccess = await this.vm.lockSelectingMonsters();
      if (isSuccess) {
        this.vmEvent.loadMyMonsters(this.vm.selectingMonsterIds);
      }
    }
  }
}
